




































  import { PAGE_CONTENT_TYPE } from '@/constant/Enums';
  import { ServerResponse } from '@/services/response.types';
  import { ProductMotorState } from '@/store/product/motor/types';
  import { AboutModel, ContentModel } from '@/store/types';
  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Action, State } from 'vuex-class';
  import filters from "@/constant/Filters";

  @Component({
    name: 'productMotor',
    components: {
      compareMotorcylce: () => import('@/components/formCompare/compareMotorcylce.vue'),
      productHelp: () => import('@/components/ProductHelp.vue'),
      protectNow: () => import('@/components/ProtectNow.vue'),
    },
    mixins: [],
    filters
  })
  export default class productMotor extends Vue {
    @State((state: ProductMotorState) => state.title.HTMLtoString(), { namespace: 'product/motor' }) title!: string;
    @State((state: ProductMotorState) => state.content.HTMLtoString(), { namespace: 'product/motor' }) content!: string;
    // @State((state: ProductMotorState) => state.banner.HTMLtoString(), { namespace: 'product/motor' }) banner !: string;
    @State('page_content') page_content!: ContentModel[];
    @State('abouts') abouts!: AboutModel[];

    public get banner(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'motor-banner')[0]?.image ?? null;
    }

    public get helpImg(): string {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'motor-help')[0]?.image ?? null;
    }

    public get information() : ContentModel {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.PRODUCT && item.name === 'motor-information')[0]??new ContentModel();
    }

    public get aboutApp() : AboutModel {
      return this.abouts.filter((v)=>v.id === 0)[0]
    }

    public banerDefault = require('@/assets/images/banner/motor.jpg');

    public get listBannerInfo() :ContentModel[] {
      return this.page_content.filter((item) => item.type === PAGE_CONTENT_TYPE.SLIDER && item.name === 'motor-slider')
    }

    public created(): void {}
    public mounted(): void {
      // on componenet mounted
    }
  }
